export default {
  ADMIN: 'admin',
  LALIGADMIN: 'laligaAdmin',
  COLLABORATOR: 'collaborator',
  PARENT: 'parent',
  PLAYER: 'player',
  FAN: 'fan',
  SUBSCRIBER: 'subscriber',
  MANAGER: 'manager',
  SUBMANAGER: 'submanager',
  SHIPPER: 'shipper',
}
