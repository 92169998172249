let vuetifyInstance

export function setVuetifyInstance(instance) {
  vuetifyInstance = instance
}

export function getVuetifyInstance() {
  if (!vuetifyInstance) {
    throw new Error('Vuetify instance not set. Please call setVuetifyInstance first.')
  }
  return vuetifyInstance
}
