import i18n from '@/plugins/i18n'

const getKeywordWithFallback = ({
  project,
  keywordType,
  defaultTranslation,
  projectTypeTranslation,
}) => {
  if (!project) return defaultTranslation
  try {
    const keyword = project?.keywords?.[keywordType]
    if (keyword) return keyword

    if (projectTypeTranslation) {
      const fallbackTranslation = i18n.t(projectTypeTranslation.replace('{type}', project?.type))
      return fallbackTranslation || defaultTranslation
    }

    return defaultTranslation
  } catch (error) {
    console.error(`Error -> projectKeywords_${keywordType}:`, {
      error,
      projectId: project?.id,
    })
    return defaultTranslation
  }
}

export const getPlayerKeyword = project => getKeywordWithFallback({
  project,
  keywordType: 'player',
  defaultTranslation: i18n.t('user.roles.player-short-term'),
  projectTypeTranslation: 'user.roles.player-{type}',
})

export const getPlayersKeyword = project => getKeywordWithFallback({
  project,
  keywordType: 'players',
  defaultTranslation: i18n.t('project.sections.players-suscribers'),
  projectTypeTranslation: 'project.sections.players-{type}',
})

export const getFanKeyword = project => getKeywordWithFallback({
  project,
  keywordType: 'fan',
  defaultTranslation: i18n.t('user.roles.fan'),
})
