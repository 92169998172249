import i18n from '@/plugins/i18n'
import store from '@/store'
import activityRoutes from '@/modules/activity/router'
import competitionRoutes from '@/modules/competition/router'
import projectUsers from '@/modules/projectUsers/router'
import postRoutes from '@/modules/post/projectRouter'
import calendarRoutes from '@/modules/calendar/router'
import pushNotificationsRoutes from '@/modules/pushNotifications/projectRouter'
import assetRoutes from '@/modules/asset/router'
import locationsRouter from '@/modules/locations/router'
import paymentsRouter from '@/modules/payments/routes/projectRouter'
import guildBenefitRequestFormRoutes from '@/modules/guildBenefitRequestsForm/router'
import inscriptionFormRoutes from '@/modules/inscriptionForm/router'
import orderRoutes from '@/modules/orders/projectRouter'
import getSidebarItems from './getSidebarItems'

export default [
  {
    path: '/organization/:organizationId/project/:projectId',
    name: 'project',
    component: () => import('@/modules/project/Project'),
    props: true,
    beforeEnter: async (to, from, next) => {
      const { organizationId, projectId } = to.params
      await store.dispatch('project/unbindBuys')
      // Binds
      store.commit('loader/show')
      if (store.state.organization.dbData?.id !== organizationId) {
        await store.dispatch('organization/bind', organizationId)
        await store.dispatch('organization/bindEventLog', organizationId)
        await store.dispatch('organization/bindClusters', organizationId)
        await store.dispatch('vendors/bindVendors', { organizationId })
        if (store.getters['organization/data'].sport !== store.getters['organization/sport']?.id) await store.dispatch('organization/bindSport', { sportId: store.getters['organization/data'].sport })
      } else {
        if (!store.state.organization?.dbSport) {
          await store.dispatch('organization/bindSport', { sportId: store.state.organization.dbData.sport })
        }
        if (!store.state.organization?.dbClusters?.length) await store.dispatch('organization/bindClusters', organizationId)
        if (!store.state.vendors?.dbVendors?.length) await store.dispatch('vendors/bindVendors', { organizationId })
      }
      const isBasketball = store.getters['organization/isBasketball']
      if (store.state.project.dbData?.id !== projectId) {
        const project = await store.dispatch('project/bind', { organizationId, projectId })
        if (!project) next({ name: '404' })
        await store.dispatch('projectUsers/unbindStaff')
        await store.dispatch('projectUsers/unbindPlayers')
        await store.dispatch('projectUsers/unbindFans')
        await store.dispatch('projectUsers/unbindParents')
        await store.dispatch('project/unbindAssets')
        await store.dispatch('project/bindRoles', { organizationId, projectId })
        await store.dispatch('project/bindEnrollments', { organizationId, projectId })
        await store.dispatch('project/bindActivities', { organizationId, projectId })
        await store.dispatch('project/bindTerms', { organizationId, projectId })
        await store.dispatch('project/bindWaiver', { organizationId, projectId })
        await store.dispatch('dynamicForm/bindDataUser', { organizationId, projectId })
        await store.dispatch('locations/bindLocations', { organizationId, projectId })
        await store.dispatch('project/bindMembershipCards', { organizationId, projectId })
        await store.dispatch('project/setRouterGuardShowMembershipCards')
        if (project?.type === 'competition') {
          await store.dispatch('competition/bindStages', { organizationId, projectId })
          await store.dispatch('competition/bindRegions', { organizationId, projectId })
          await store.dispatch('competition/bindCategories', { organizationId, projectId })
          await store.dispatch('competition/bindCohorts', { organizationId, projectId })
          await store.dispatch('competition/bindClubs', { organizationId, projectId })
          await store.dispatch('competition/bindClubEnrollments', { organizationId, projectId })
          await store.dispatch('project/bindServices', { organizationId, projectId })
          await store.dispatch('competition/bindBanTypes', { organizationId, projectId })
          if (isBasketball) await store.dispatch('competition/bindFranchises', { organizationId })
          await store.dispatch('dynamicForm/bindDataClub', { organizationId, projectId })
        }
        if (project?.type === 'academy') {
          await store.dispatch('academy/bindCategories', { organizationId, projectId })
          await store.dispatch('academy/bindTeams', { organizationId, projectId })
          await store.dispatch('academy/bindParents', { organizationId, projectId })
        }
        if (project?.clusterId) await store.dispatch('cluster/bind', { organizationId, clusterId: project.clusterId })
        await store.dispatch('projectUsers/bindStaff', { organizationId, projectId })
        await store.dispatch('project/bindCoupons', { projectId })
        await store.dispatch('organization/bindProducts', { organizationId })
      } else {
        const project = store.state.project.dbData
        if (!store.state.project.dbRoles.length) await store.dispatch('project/bindRoles', { organizationId, projectId })
        if (!store.state.project.dbEnrollments.length) await store.dispatch('project/bindEnrollments', { organizationId, projectId })
        if (!store.state.project.dbActivities.length) await store.dispatch('project/bindActivities', { organizationId, projectId })
        if (!store.state.project.dbTerms.length) await store.dispatch('project/bindTerms', { organizationId, projectId })
        if (!store.state.project.dbWaiver.length) await store.dispatch('project/bindWaiver', { organizationId, projectId })
        if (!store.state.dynamicForm.dbDataUser) await store.dispatch('dynamicForm/bindDataUser', { organizationId, projectId })
        if (!store.state.project.dbMembershipCards.length) await store.dispatch('project/bindMembershipCards', { organizationId, projectId })
        if (project?.type === 'competition') {
          if (!store.state.competition.dbCategories.length) await store.dispatch('competition/bindCategories', { organizationId, projectId })
          if (!store.state.competition.dbRegions.length) await store.dispatch('competition/bindRegions', { organizationId, projectId })
          if (!store.state.competition.dbClubs.length) await store.dispatch('competition/bindClubs', { organizationId, projectId })
          if (!store.state.competition.dbClubEnrollments.length) await store.dispatch('competition/bindClubEnrollments', { organizationId, projectId })
          if (!store.state.competition.dbCohorts.length) await store.dispatch('competition/bindCohorts', { organizationId, projectId })
          if (!store.state.competition.dbStages.length) await store.dispatch('competition/bindStages', { organizationId, projectId })
          if (!store.state.project.dbServices.length) await store.dispatch('project/bindServices', { organizationId, projectId })
          if (!store.state.competition.dbBanTypes.length) await store.dispatch('competition/bindBanTypes', { organizationId, projectId })
          if (isBasketball && !store.state.competition.dbFranchises.length) await store.dispatch('competition/bindFranchises', { organizationId })
          if (!store.state.dynamicForm.dbDataClub) await store.dispatch('dynamicForm/bindDataClub', { organizationId, projectId })
        }
        if (project?.type === 'academy') {
          if (!store.state.academy.dbCategories.length) await store.dispatch('academy/bindCategories', { organizationId, projectId })
          if (!store.state.academy.dbTeams.length) await store.dispatch('academy/bindTeams', { organizationId, projectId })
          if (!store.state.academy.dbParents.length) await store.dispatch('academy/bindParents', { organizationId, projectId })
        }
        if (!store.state.locations.dbLocations.length) await store.dispatch('locations/bindLocations', { organizationId, projectId })
        if (project?.clusterId && !store.state.cluster.dbData) await store.dispatch('cluster/bind', { organizationId, clusterId: project.clusterId })
        if (!store.state.projectUsers.dbStaff.length) await store.dispatch('projectUsers/bindStaff', { organizationId, projectId })
        if (!store.state.organization.dbCoupons) await store.dispatch('project/bindCoupons', { projectId })
        if (!store.state.organization.dbProducts) await store.dispatch('organization/bindProducts', { organizationId })
      }
      store.commit('loader/hide')

      // Security
      const isUserOrganizationAdmin = store.getters['user/adminOrganizationIds'].includes(to.params.organizationId)
      const isUserProjectStaff = store.getters['user/staffProjectIds'].includes(to.params.projectId)
      const project = store.state.project.dbData
      const isUserClusterManager = store.getters['user/managerClusterIds'].includes(project?.clusterId)
      if (!(isUserOrganizationAdmin || isUserProjectStaff || isUserClusterManager || store.getters['superadmin/isUserSuperadmin'])) next({ name: '404' })
      const sidebarItems = getSidebarItems()
      const section = sidebarItems.find(item => item.route?.name === to.name)
      if (section) {
        if (sidebarItems.find(item => item.id === section.id)) next()
        else next({ name: '404' })
      } else if (to.name === 'project') {
        const { route } = sidebarItems.find(item => item.route)
        next({ ...route, params: { organizationId, projectId } })
      } else next()
    },
    children: [
      {
        path: 'settings',
        name: 'project-edit',
        component: () => import('@/modules/project/views/ProjectSettingsFormView.vue'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.settings')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.settings'),
        },
        props: true,
      },
      {
        path: 'guild-settings',
        name: 'guild-edit',
        component: () => import('@/modules/guild/EditGuildAndGuildClub.vue'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.settings')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.settings'),
        },
        props: true,
      },
      {
        path: 'sponsorization',
        name: 'project-sponsorization',
        component: () => import('@/modules/project/SponsorizationProject'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.settings')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.sponsorization'),
        },
        props: true,
      },
      {
        path: 'categories-and-teams',
        name: 'project-categories-and-teams',
        component: () => import('@/modules/academy/CategoriesAndTeams'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.categories')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.categories'),
        },
        props: true,
      },
      {
        path: 'hotels',
        name: 'project-accomodations',
        component: () => import('@/modules/project/Accomodations'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.accomodations')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.accomodations'),
        },
        props: true,
      },
      {
        path: 'terms-and-conditions',
        name: 'project-terms-and-conditions',
        component: () => import('@/modules/project/views/TermsAndConditionsView.vue'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('common.t&c')} - OWQLO`,
          headerTitle: () => i18n.t('common.t&c'),
        },
        props: true,
      },
      {
        path: 'keywords',
        name: 'project-keywords',
        component: () => import('@/modules/project/views/ProjectKeywordsView.vue'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.keywords')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.keywords'),
        },
        props: true,
      },
      {
        path: 'enrollments',
        name: 'project-enrollments',
        component: () => import('@/modules/project/views/EnrollmentListView.vue'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.enrollments')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.enrollments'),
        },
        props: true,
      },
      {
        path: 'emails',
        name: 'project-emailAdmission',
        component: () => import('@/modules/project/emailSettings/EmailSettings'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.emailAdmission')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.emailAdmission'),
        },
        props: true,
        children: [
          {
            path: 'clubs',
            name: 'project-emailAdmission-clubs',
            component: () => import('@/modules/project/emailSettings/Clubs'),
            meta: {
              title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.emailAdmission')} - OWQLO`,
              headerTitle: () => i18n.t('project.sections.emailAdmission'),
            },
            props: true,
          },
          {
            path: 'fans',
            name: 'project-emailAdmission-fans',
            component: () => import('@/modules/project/emailSettings/Fans'),
            meta: {
              title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.emailAdmission')} - OWQLO`,
              headerTitle: () => i18n.t('project.sections.emailAdmission'),
            },
            props: true,
          },
          {
            path: 'players',
            name: 'project-emailAdmission-players',
            component: () => import('@/modules/project/emailSettings/Players'),
            meta: {
              title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.emailAdmission')} - OWQLO`,
              headerTitle: () => i18n.t('project.sections.emailAdmission'),
            },
            props: true,
          },
          {
            path: 'parents',
            name: 'project-emailAdmission-parents',
            component: () => import('@/modules/project/emailSettings/Parents'),
            meta: {
              title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.emailAdmission')} - OWQLO`,
              headerTitle: () => i18n.t('project.sections.emailAdmission'),
            },
            props: true,
          },
        ],
      },
      {
        path: 'log',
        name: 'project-eventLog',
        component: () => import('@/components/EventLog'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.eventLog')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.eventLog'),
        },
        props: true,
      },
      {
        path: 'calendar/day/:date',
        name: 'project-calendar',
        component: () => import('@/modules/project/ActivityList'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.activities')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.activities'),
        },
        props: true,
      },
      {
        path: 'coupons',
        name: 'project-coupons',
        component: () => import('@/modules/coupons/CouponsList.vue'),
        meta: {
          title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.coupons')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.coupons'),
        },
        props: true,
      },
      {
        path: 'membershipCards',
        name: 'project-membershipCards',
        component: () => import('@/modules/project/views/ProjectMembershipCardsListView.vue'),
        meta: {
          title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.membershipCards')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.membershipCards'),
        },
        props: true,
        beforeEnter: async (to, from, next) => {
          if (store.getters['project/routerGuardShowMembershipCards']) next()
          else next({ name: '404' })
        },
      },
      {
        path: 'membershipCard/create',
        name: 'project-membershipCard-create',
        component: () => import('@/modules/project/views/ProjectMembershipCardView.vue'),
        redirect: { name: 'project-membershipCard-create-configuration' },
        props: true,
        beforeEnter: async (to, from, next) => {
          if (store.getters['project/routerGuardShowMembershipCards']) next()
          else next({ name: '404' })
        },
        children: [
          {
            path: 'configuration',
            name: 'project-membershipCard-create-configuration',
            component: () => import('@/modules/project/components/forms/ProjectMembershipCardForm.vue'),
            meta: {
              title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.membershipCards')} - OWQLO`,
              headerTitle: () => i18n.t('project.sections.membershipCards'),
            },
            props: true,
          },
        ],
      },
      {
        path: 'membershipCard/edit/:membershipCardId',
        name: 'project-membershipCard-edit',
        component: () => import('@/modules/project/views/ProjectMembershipCardView.vue'),
        redirect: { name: 'project-membershipCard-edit-configuration' },
        props: true,
        beforeEnter: async (to, from, next) => {
          if (store.getters['project/routerGuardShowMembershipCards']) next()
          else next({ name: '404' })
        },
        children: [
          {
            path: 'configuration',
            name: 'project-membershipCard-edit-configuration',
            component: () => import('@/modules/project/components/forms/ProjectMembershipCardForm.vue'),
            meta: {
              title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.membershipCards')} - OWQLO`,
              headerTitle: () => i18n.t('project.sections.membershipCards'),
            },
            props: true,
          },
          {
            path: 'activities',
            name: 'project-membershipCard-activities',
            component: () => import('@/modules/project/components/tables/ProjectMembershipCardActivitiesTable.vue'),
            meta: {
              title: () => `${store.getters['project/data'].name} - ${i18n.t('project.sections.membershipCards')} - OWQLO`,
              headerTitle: () => i18n.t('project.sections.membershipCards'),
            },
            props: true,
          },
        ],
      },
      {
        path: 'products',
        name: 'project-products',
        component: () => import('@/modules/products/views/ProductListView.vue'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('common.products')} - OWQLO`,
          headerTitle: () => i18n.t('common.products'),
        },
        props: true,
      },
      {
        path: 'product/create',
        name: 'project-product-create',
        component: () => import('@/modules/products/views/ProductForm.vue'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('common.products')} - OWQLO`,
          headerTitle: () => i18n.t('common.products'),
        },
        props: true,
      },
      {
        path: 'product/edit/:archetypeId',
        name: 'project-product-edit',
        component: () => import('@/modules/products/views/ProductForm.vue'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('common.products')} - OWQLO`,
          headerTitle: () => i18n.t('common.products'),
        },
        props: true,
      },
      ...activityRoutes,
      ...competitionRoutes,
      ...projectUsers,
      ...postRoutes,
      ...calendarRoutes,
      ...pushNotificationsRoutes,
      ...assetRoutes,
      ...locationsRouter,
      ...paymentsRouter,
      ...guildBenefitRequestFormRoutes,
      ...inscriptionFormRoutes,
      ...orderRoutes,
    ],
  },
]
