import i18n from '@/plugins/i18n'
import store from '@/store'

export default [
  {
    path: 'schedule',
    name: 'calendar-schedule',
    component: () => import('@/modules/calendar/views/calendar/MainCalendarView.vue'),
    meta: {
      title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.schedule')} - OWQLO`,
      headerTitle: () => i18n.t('project.sections.schedule'),
    },
    props: true,
    redirect: { name: 'calendar-schedule-my-calendar' },
    children: [
      {
        path: 'my-calendar',
        name: 'calendar-schedule-my-calendar',
        component: () => import('@/modules/calendar/views/calendar/MyCalendarView.vue'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.schedule')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.schedule'),
        },
        props: true,
      },
      {
        path: 'calendar-requests',
        name: 'calendar-schedule-requests',
        component: () => import('@/modules/calendar/views/calendar/CalendarRequestsView.vue'),
        meta: {
          title: () => `${store.getters['project/data']?.name} - ${i18n.t('project.sections.schedule')} - OWQLO`,
          headerTitle: () => i18n.t('project.sections.schedule'),
        },
        props: true,
      },
    ],
  },
]
